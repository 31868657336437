import Vue from 'vue';
import Vuex from 'vuex';
import bill from './modules/bill';

import createLogger from 'vuex/dist/logger'

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        bill,
    },
    strict: process.env.NODE_ENV == 'development',
    plugins: process.env.NODE_ENV == 'development' ? 
        [createLogger()] :
        []
})