<template>
    <div v-show="ajaxCount>0" class="blocker">
        <bean-eater></bean-eater>
    </div>
</template>

<script>
import {eventBus} from "../../js/eventBus";
import beanEater from "./beanEater";

export default {
    name:"blocker",
    components:{
        beanEater
    },
    data(){
        return {
            ajaxCount:0,
        };
    },
    created(){
        eventBus.$on("ajaxRequest", (isInc)=>{
            if(isInc) this.ajaxCount++;
            else this.ajaxCount--;
        })
    }
}
</script>

<style scoped>
    .blocker{
        position: fixed;
        top: 0px; bottom: 0px;
        left: 0px; right:0px;
        z-index: 2000;
        background-color: rgba(200, 200, 200, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>