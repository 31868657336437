import VueRouter from 'vue-router';
import BillApp from '~/components/bill/app.vue'

export default new VueRouter({
    routes:[{
        path: '/bill/:billId',
        name: "bill",
        component: BillApp
    }]
})
