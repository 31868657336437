import axios from 'axios';
import iziToast from "izitoast";
import { eventBus } from './eventBus';

const isHandlerDisabled = (config = {}) => {
    return config.hasOwnProperty('handlerDisabled') && config.handlerDisabled ?
        true : false
}

const instance = axios.create({
    baseURL: process.env.BASE_URL,
    validateStatus: function(status){
        return (status >= 200 && status < 300) || status == 304;
    }
});

instance.interceptors.response.use(
    res => res,
    function (error) {
        console.log(error)
        if (!isHandlerDisabled(error.config)) {
            // handle error
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                    iziToast.error({
                        title: `서버 요청중 에러 발생 (${error.response.status})`,
                        message: error.response.data.message
                    })
                } else {
                    iziToast.error({
                        title: `서버 요청중 에러 발생 (${error.response.status})`,
                        message: error.response.data
                    })
                }
            } else if (error.request) {
                iziToast.error({
                    title: "서버 요청중 에러 발생"
                })
            } else {
                iziToast.error({
                    title: "서버 요청중 에러 발생",
                    message: error.message
                })
            }
        }
        return Promise.reject(error);
    }
);

instance.interceptors.request.use(function (config) {
    eventBus.$emit("ajaxRequest", true);
    return config;
}, function (error) {
    eventBus.$emit("ajaxRequest", true);
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    eventBus.$emit("ajaxRequest", false);
    return response;
}, function (error) {
    eventBus.$emit("ajaxRequest", false);
    return Promise.reject(error);
});

export default instance;