import axios from '~/js/axiosWrapper';

const initialState = () => ({
    bill: null,
    studentInfos: [],
    parentInfo: null,
    status: "",
    notExists: false
})

const getters = {}

const actions = {
    getBill({ commit }, { billCode }){
        return axios.post(`/payment/bill/${billCode}`)
        .then(({ status, data })=>{
            if(data.success){
                if(data.data != null)
                    commit("bill", data.data);
                else
                    commit("empty")
            } else
                throw Error('정보 조회 실패');
        })
    },
    account({ commit }, data){
        return axios.post(`/payment/bill/account`, data)
        .then(({ status, data })=>{
            if(data.success){
                return true;
            } else{
                throw Error('서버 요청 실패');
            }
        })
    }
}

const mutations = {
    bill(state, data){
        state.bill = data.bill;
        state.studentInfos = data.studentInfos;
        state.parentInfo = data.parentInfo;
        state.status = data.status;
    },
    empty(state){
        state.notExists = true
    }
}

export default {
    namespaced: true,
    state : initialState,
    getters,
    actions,
    mutations
}