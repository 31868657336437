import '../scss/main.scss';

import './common.js';
import Vue from 'vue';
import router from './router';
import store from '~/store/index';

const app = new Vue({
    el:"#app",
    router,
    store
})