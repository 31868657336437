<template>
    <div class="bill-container">
        <template v-if="bill != null && isPaid != true">
        <div class="bill-title">
            <div class="title mb-2">인코딩플러스 학원</div>
            <div class="light-text mb-2">{{studentName}}</div>
            <div class="title font-weight-bold text-right">{{bill.totalAmount | numberFormat}}원</div>
        </div>
        <div class="bill-detail">
            <div class="sub-title">수업 내역</div>
            <div v-for="tuition in bill.tuitions" :key="tuition.date"
                class="mt-3">
                <div class="sub-title">{{tuition.date.slice(5, 7)}}월</div>
                <div v-for="course in tuition.courses" :key="course.courseInfoId">
                    <div class="mt-2" v-if="course.courseCount > 0">
                        <div>{{course.courseTitle.replace(/\(.*\)/g, "").trim()}}</div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="light-text">{{course.originalTuition | numberFormat}}원 / 주 {{course.baseCourseCount}}회</div>
                            <div>{{calculateTuitionOfCourse(course) | numberFormat}}원</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bill-result mb-3">
            <div class="sub-result">
                <div class="sub-title mb-3">최종 결제 금액</div>
                <div class="d-flex justify-content-between mb-2">
                    <div>수강료</div>
                    <div>{{totalFinalTuition | numberFormat}}원</div>
                </div>
                <div class="d-flex justify-content-between paid">
                    <div>기납부액</div>
                    <div>{{-totalPaidAmount | numberFormat}}원</div>
                </div>
            </div>
            <div class="result">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="sub-title">총 결제 금액</div>
                    <div class="title">{{bill.totalAmount | numberFormat}}원</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-danger" v-if="paymentStatus == '결제취소'">
                    <div class="sub-title">결제 취소 금액</div>
                    <div class="title">{{-bill.totalAmount | numberFormat}}원</div>
                </div>
            </div>
        </div>
        <div class="bill-footer">
            <button class="btn w-100" @click="pay" v-if="payable == true">{{bill.totalAmount | numberFormat}}원 결제하기</button>
            <button class="btn w-100" v-else disabled>{{paymentStatus}}됨</button>
        </div>
        </template>
        <template v-else-if="isPaid == true">
        <div class="paid-success d-flex justify-content-center align-items-center">
            <div class="text-center">결제 완료<br>감사합니다</div>
        </div>
        </template>
        <template v-else-if="notExists == true">
        <div class="paid-success d-flex justify-content-center align-items-center">
            <div class="text-center">결제완료 혹은 존재하지 않는 청구서입니다</div>
        </div>
        </template>
        <template v-else>
        <div class="loader d-flex justify-content-center align-items-center flex-column">
            <bean-eater/>
            <div class="sub-title">인코딩플러스 결제창 로딩중...</div>
        </div>
        </template>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import BootpayJS from 'bootpay-js';
import { v4 as uuidv4 } from 'uuid';

import BeanEater from '~/components/common/beanEater';

export default {
    name: "bill.main",
    components: {
        BeanEater
    },
    data(){
        return {
            isPaid: false
        }
    },
    methods: {
        calculateTuitionOfCourse(course){
            return course.originalTuition * (course.courseCount / course.baseCourseCount) * (1 - course.discountRate) + course.discountAmount;
        },
        pay(){
            if(this.payable != true) {
                this.$toast.warning({ title: `해당 청구서는 결제가 불가합니다 사유: ${this.paymentStatus}`})
                return;
            }

            const app = this;

            //실제 복사하여 사용시에는 모든 주석을 지운 후 사용하세요
            BootpayJS.request({
                price: this.bill.totalAmount, //실제 결제되는 가격
                tax_free: this.bill.totalAmount,
                application_id: process.env.BOOTPAY_APP_ID,
                name: '인코딩플러스 수업료', //결제창에서 보여질 이름
                pg: 'payapp',
                method: 'card', //결제수단, 입력하지 않으면 결제수단 선택부터 화면이 시작합니다.
                show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
                user_info: {
                    phone: this.parentInfo.phoneNumber
                },
                order_id: uuidv4(), //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
                params: {
                    identifyCode: this.billId
                },
                extra: {
                    seller_name: "인코딩플러스 학원",
                    quota: [1,2,3,4,5,6],
                    popup: BootpayJS.isMobileSafari()
                }
            }).error(function (data) {
                //결제 진행시 에러가 발생하면 수행됩니다.
                app.$toast.error({
                    title: "결제 에러",
                    message: "에러가 발생했습니다. 학원에 문의해주세요."
                })
                console.log(data);
            }).cancel(function (data) {
                //결제가 취소되면 수행됩니다.
                app.$toast.warning({
                    title: data.message,
                })
                console.log(data);
            }).ready(function (data) {
                // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
                console.log(data);
                app.$store.dispatch("bill/account", data)
                .then(()=>{
                    app.$toast.success({
                        title: "발급완료",
                    })
                    this.$store.dispatch("bill/getBill", { billCode: this.billId })
                }, (err)=>{
                    app.$toast.error({
                        title: "서버 에러 발생",
                    })
                    this.$store.dispatch("bill/getBill", { billCode: this.billId })
                });
            }).confirm(function (data) {
                //결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
                BootPay.transactionConfirm(data);
                console.log(data);
            }).close(function (data) {
                // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
                console.log(data);
            }).done(function (data) {
                //결제가 정상적으로 완료되면 수행됩니다
                //비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
                console.log(data);
                app.$toast.success({
                    title: "결제에 성공했습니다."
                });
                app.isPaid = true;
            });
        }
    },
    computed: {
        billId(){
            return this.$route.params.billId;
        },
        bill(){
            return this.$store.state.bill.bill;
        },
        studentName(){
            return this.$store.state.bill.studentInfos.map(el => el.name.replace(/\(.*\)/g, "").trim()).join(", ")
        },
        parentInfo(){
            return this.$store.state.bill.parentInfo;
        },
        notExists(){
            return this.$store.state.bill.notExists;
        },
        totalFinalTuition(){
            return this.bill.tuitions.reduce((sum, el) => sum + el.finalTuition, 0)
        },
        totalPaidAmount(){
            return this.bill.tuitions.reduce((sum, el) => sum + el.paidAmount, 0)
        },
        paymentStatus(){
            return this.$store.state.bill.status;
        },
        payable(){
            return this.paymentStatus != '결제취소' && this.paymentStatus != '결제완료' && this.paymentStatus != '가상계좌발급' && this.isExpired == false;
        },
        isExpired(){
            return new Date(this.bill.expiresAt) < +new Date() - 1000 * 60 * 10 // 실제 파기 10분 전에 검사.
        }
    },
    mounted(){
        // console.log(this.billId)
        this.$store.dispatch("bill/getBill", { billCode: this.billId })
    }
}
</script>
<style lang="scss" scoped>
$padding-x: 16px;
$padding-y: 28px;

.bill-container{
    max-width: 640px;
    margin: auto;

    .title{
        font-size: 1.222rem;
        font-weight: 500;
    }
    .sub-title{
        font-weight: 500;
    }
    .light-text{
        font-size: 0.888rem;
        color: #777777;
    }

    .bill-title{
        padding: $padding-y $padding-x;
    }

    .bill-detail{
        padding: 20px $padding-x 20px $padding-x;
        border-bottom: 1px solid #777777;
        border-top: 1px solid #777777;
    }

    .bill-result{
        .sub-result{
            padding: 20px $padding-x 20px $padding-x;
            .paid{
                color: #777777;
            }
        }
        .result{
            padding: 20px $padding-x 20px $padding-x;
            border-top: 1px solid #DEDEDE;
        }
        border-bottom: 1px solid #777777;
    }

    .bill-footer{
        position: sticky;
        bottom: 0px;
        padding: $padding-y $padding-x;
        background-color: white;
        box-shadow: 0 -20px 20px #FEFEFE;
        .btn {
            padding: 15px 0;
            font-size: 18px;
            background-color: #0045B3;
            color: white;
            &:not(:disabled){
                cursor: pointer;
                &:hover{
                    background-color: lighten(#0045B3, 5%);
                }
            }

        }
    }

    .paid-success{
        position: fixed;
        top: 0; bottom: 0;
        left: 0; right: 0;
    }

    .loader{
        position: fixed;
        top: 0; bottom: 0;
        left: 0; right: 0;
    }
}

</style>